@font-face {
  font-family: "Regular";
  src: url("../src/fonts/Prompt/Prompt-Regular.ttf");
}

@font-face {
  font-family: "SemiBold";
  src: url("../src/fonts/Prompt/Prompt-SemiBold.ttf");
}

body {
  font-family: "Regular" !important;
}

::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px; /* width of vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(38, 38, 38);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(99, 99, 99);
  border-radius: 10px;
}

.swal2-styled.swal2-confirm {
  font-family: "Regular" !important;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  /* background-color: rgb(48, 133, 214) !important; */
  background-color: rgb(138, 216, 121) !important;
  color: #000;
  font-size: 1.0625em;
}

.swal2-styled.swal2-cancel {
  font-family: "Regular" !important;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: rgb(243, 83, 58) !important;
  color: #000;
  font-size: 1.0625em;
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-popup {
  font-family: "Regular" !important;
  font-size: 0.9rem !important;
  width: 26rem !important;
}

.swal2-input {
  font-family: "Regular" !important;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  width: 100%;
  position: relative;
  flex-direction: column;
  vertical-align: top;
}

.MuiFormHelperText-contained {
  font-size: 12;
  font-family: "Regular" !important;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

.MuiOutlinedInput-inputMultiline {
  padding-top: 5px !important;
}

.MuiCardHeader-action {
  flex: 0 0 auto;
  align-self: flex-start;
  margin-top: 0 !important;
  margin-right: -8px;
}

/* image carousel */

.carousel-container {
  margin: 20px;
}

.selected-image {
  width: 100%;
  height: 500px;
  margin-bottom: 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel {
  position: relative;
}

.carousel__images {
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
}

.carousel__image-selected {
  border: 2px solid rgb(63 81 181 / 80%) !important;
}

.carousel__image {
  margin-right: 10px;
  height: 80px;
  min-width: 100px;
  border: solid 1px #e6e6e6;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel__button {
  position: absolute;
  top: 35%;
}

.carousel__button-left {
  left: 10px;
}

.carousel__button-right {
  right: 10px;
}

.pac-container,
.pac-item {
  z-index: 2147483647 !important;
}

.gm-style-iw > button {
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
